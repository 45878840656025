.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &[data-parallax] {
    position: relative;
    overflow: hidden;
  }
}
