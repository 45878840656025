.modal {
  pointer-events: none;
  position: fixed;
  inset: 0;
  z-index: 3;
  width: 100vw;
  min-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background: hsla(200 2% 6% / 0.3);
  backdrop-filter: blur(10px);
  opacity: 0;

  &.modal--in {
    opacity: 1;
    transition: opacity 400ms cubic-bezier(0, 0, 0.5, 1);
  }

  &.modal--out {
    opacity: 0;
    transition: opacity 550ms cubic-bezier(.24, 0, .71, 1.01);
  }

  &__scroller {
    position: relative;
    z-index: 2;
    min-height: 100%;
    display: grid;
    place-content: center;
  }

  &__container {
    position: relative;
    z-index: 3;
    width: 100vw;
    max-width: 60rem;
    padding: 2rem;
  }
}

.modal-content {border: 6px solid Chocolate;
  position: relative;
  width: fit-content;
  transform: translateY(20vh);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 860;
  padding: 6vmin 8vmin;
  border-radius: 2.6vmin;
  opacity: 0;
  background-color: Goldenrod;

  .modal--in & {
    opacity: 1;
    transform: translateY(0);
    transition:
            opacity 400ms cubic-bezier(.61, 1, .88, 1),
            transform 700ms cubic-bezier(0.22, 1.45, 0.36, 1);
  }

  .modal--out & {
    opacity: 0;
    transform: translateY(20vh);
    transition:
            opacity 500ms cubic-bezier(0, 0, 0.5, 1),
            transform 550ms cubic-bezier(0.22, 1.45, 0.36, 1);
  }
}
