.btn-pick {
  overflow: hidden;
  position: relative;
  display: grid;
  place-content: center;

  width: fit-content;
  height: 52px;
  padding: 0 1.2em;
  border-radius: 16px;
  backdrop-filter: blur(0);
  opacity: 0;

  will-change: transform;

  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  text-transform: uppercase;

  background-image: radial-gradient(circle at center, rgba(orange, 0.25), rgba(darken(yellow, 15), 0.5));
  color: WhiteSmoke;
  text-shadow: 0 1px 2px rgba(black, 0.2), 0 2px 12px rgba(black, 0.4);
  cursor: pointer;
  box-shadow:
          0 5px 10px rgba(black, 0.75),
          inset 0 0 0 2px rgba(black, 0.3),
          inset 0 -6px 6px -3px rgba(darken(orange, 10), 0.2);

  @media (width >= 992px) {
    height: 64px;
    font-size: 18px;
  }

  span {
    position: relative;
    z-index: 1;
    transform: translateY(1px);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }

  &::after {
    inset: 2px;
    height: 50%;
    background-image: linear-gradient(rgba(white, 0.6), rgba(white, 0.1));
    border-radius: 200px;
  }

  &::before {
    inset: 0;
    background: rgba(Gold, 0.3);
    backdrop-filter: blur(3px);
  }
}
