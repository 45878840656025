.chests {
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 1vmin;
  width: clamp(300px, 90vmin, 1200px);

  &__item {

  }
}

.chest {
  aspect-ratio: 1;
  will-change: transform;

  &:not(.win):not(.loose) {
    .image-chest-loose,
    .image-chest-win {
      display: none;
    }
  }

  &.win {
    .image-chest-closed,
    .image-chest-loose {
      display: none;
    }

    filter: drop-shadow(0 -2vmax 5vmax hsla(var(--shadow-color) 50% 50% / 1));
  }

  &.loose {
    .image-chest-closed,
    .image-chest-win {
      display: none;
    }

    filter: grayscale(70%) brightness(60%);
  }
}
