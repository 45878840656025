html,
body {
  overflow-x: hidden;
  position: relative;
  width: 100vw;
}

body {
  overflow: hidden;
  display: grid;
  place-content: center;
  height: 100vh;
  color: WhiteSmoke;
  background-color: #121212;
}

.game {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  gap: 12vh 0;
  justify-items: center;
  margin-top: 20vh;
}

.page-bg {
  position: fixed;
  inset: 0;
}
